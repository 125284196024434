import { DOCUMENT } from '@angular/common';
import { inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { assertInjector } from 'ngxtension/assert-injector';
import { merge, fromEvent, map, startWith } from 'rxjs';

/**
 * This injector is useful for tracking the current network state of the user. It provides information about the system's connection type, such as 'wifi' or 'cellular'. This utility, along with a singular property added to the Navigator interface (Navigator.connection), allows for the identification of the general type of network connection a system is using. This functionality is particularly useful for choosing between high definition or low definition content depending on the user's network connection.
 *
 * @example
 * ```ts
 * const network = injectNetwork();
 * effect(() => {
 *  console.log(this.network.type());
 *  console.log(this.network.downlink());
 *  console.log(this.network.downlinkMax());
 *  console.log(this.network.effectiveType());
 *  console.log(this.network.rtt());
 *  console.log(this.network.saveData());
 *  console.log(this.network.online());
 *  console.log(this.network.offlineAt());
 *  console.log(this.network.onlineAt());
 *  console.log(this.network.supported());
 *  });
 *  ```
 *
 * @param options An optional object with the following properties:
 *   - `window`: (Optional) Specifies a custom `Window` instance. This is useful when working with iframes or in testing environments where the global `window` might not be appropriate.
 *   - `injector`: (Optional) Specifies a custom `Injector` instance for dependency injection. This allows for more flexible and testable code by decoupling from a global state or context.
 *
 * @returns A readonly object with the following properties:
 *  - `supported`: A signal that emits `true` if the browser supports the Network Information API, otherwise `false`.
 *  - `online`: A signal that emits `true` if the user is online, otherwise `false`.
 *  - `offlineAt`: A signal that emits the time since the user was last connected.
 *  - `onlineAt`: A signal that emits the time since the user was last disconnected.
 *  - `downlink`: A signal that emits the download speed in Mbps.
 *  - `downlinkMax`: A signal that emits the max reachable download speed in Mbps.
 *  - `effectiveType`: A signal that emits the detected effective speed type.
 *  - `rtt`: A signal that emits the estimated effective round-trip time of the current connection.
 *  - `saveData`: A signal that emits `true` if the user activated data saver mode, otherwise `false`.
 *  - `type`: A signal that emits the detected connection/network type.
 */
function injectNetwork({
  injector,
  window: customWindow
} = {}) {
  return assertInjector(injectNetwork, injector, () => {
    const window = customWindow ?? inject(DOCUMENT).defaultView;
    const navigator = window?.navigator;
    const supported = signal(window?.navigator && 'connection' in window.navigator);
    const online = signal(true);
    const saveData = signal(false);
    const offlineAt = signal(undefined);
    const onlineAt = signal(undefined);
    const downlink = signal(undefined);
    const downlinkMax = signal(undefined);
    const rtt = signal(undefined);
    const effectiveType = signal(undefined);
    const type = signal('unknown');
    const connection = supported() && navigator.connection;
    const updateNetworkInformation = () => {
      if (!navigator) return;
      offlineAt.set(online() ? undefined : Date.now());
      onlineAt.set(online() ? Date.now() : undefined);
      if (connection) {
        downlink.set(connection.downlink);
        downlinkMax.set(connection.downlinkMax);
        effectiveType.set(connection.effectiveType);
        rtt.set(connection.rtt);
        saveData.set(connection.saveData);
        type.set(connection.type);
      }
    };
    if (window) {
      merge(fromEvent(window, 'online').pipe(map(() => true)), fromEvent(window, 'offline').pipe(map(() => false))).pipe(takeUntilDestroyed()).subscribe(isOnline => {
        online.set(isOnline);
        if (isOnline) {
          onlineAt.set(Date.now());
        } else {
          offlineAt.set(Date.now());
        }
      });
    }
    if (connection) {
      fromEvent(connection, 'change').pipe(startWith(null),
      // we need to start with null to trigger the first update
      takeUntilDestroyed()).subscribe(() => updateNetworkInformation());
    }
    return {
      supported: supported.asReadonly(),
      online: online.asReadonly(),
      saveData: saveData.asReadonly(),
      offlineAt: offlineAt.asReadonly(),
      onlineAt: onlineAt.asReadonly(),
      downlink: downlink.asReadonly(),
      downlinkMax: downlinkMax.asReadonly(),
      effectiveType: effectiveType.asReadonly(),
      rtt: rtt.asReadonly(),
      type: type.asReadonly()
    };
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { injectNetwork };
